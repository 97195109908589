@font-face {
    font-family: roboto;
    src: url("assets/fonts/RobotoCondensed-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: robotobold;
    src: url("assets/fonts/RobotoCondensed-Bold.ttf") format("truetype");
  }

  .custom-container{
    padding:0px 100px;
    }

    *{
      font-family: roboto;
    }

  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  // .fixed-top {
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   z-index: 1030;
  // }

//   * {
//   scrollbar-width: thin;
//   }

.caseStudy{
  color: #fff!important;
}

.mobilemenu {
  display: none;
}

a,p,span{
    font-family: roboto;
}

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(180, 179, 179);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #066f8c;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;  background: #074861;}

.notAllowed{
  cursor: not-allowed;
}

.fontBold{
  font-family: robotobold!important;
  font-size: 18px;
}

.lineHeight{
  line-height: 45px!important;
}

.limitTexttwolines{
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.limitTextthreelines{
  display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hoMe, .desiGnation{
  font-family: robotobold!important;

}

.close{
  float: right;
  font-size: 12px;
    line-height: 1;
    color: #000;
    opacity: .6;
}


.pointer {
  cursor: pointer;
}

.mat-simple-snackbar {
  margin: auto;
  text-align: center;
  justify-content: center !important;
}

.successsnack {
  background-color: #29CA64;
  color: #ffffff;
  position: absolute !important;
  top: 70px;
  left: 38%;
}

.errorsnack {
  background-color: #C70039;
  color: #ffffff;
  position: absolute !important;
  top: 70px;
  left: 38%;
  padding-left: 50px !important;
}


.centerSnackbar {
  position: fixed !important;
  left: 50% !important;
  top: 40% !important;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
}

.board-semhead {
  color: #39F;
  font-size: 16px;
}

.board-head {
  font-size: 36px;
  color: #4f4f4f;
}

.member-designation {
  font-size: 22px;
  color: #828282;
}

.member-designation-popup {
  font-size: 18px;
  color: #828282;
}

.member-designation-content {
  padding-top: 0.7rem;
  color: #4f4f4f;
  line-height: 22px;

}

button[data-bs-dismiss="modal"]:focus,
button[data-bs-dismiss="modal"]:active {
  outline: none !important;
  box-shadow: none;
}

.border-bottom-orange {
  border-bottom: 2px solid #FA9917;
}

.capabilitiMobileShow{
  display: none!important;
}

.termConditions{
color: #8E8E8E;
font-size: 16px !important;
}


@media (min-width: 2560px) {
  .whySpsoft img, .whySpsoftDetail img{
    margin: 0px auto;
    display: block;
  }
}


@media (min-width: 1201px) and (max-width: 1399px) {
  .custom-container {
    padding:0px 100px;

  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .custom-container {
       padding:0px 80px;

  }

  .counter-box .counter {
    font-size: 32px!important;
  }

  .navbar-toggler {
    display: none;
  }

  .mobilemenu {
    display: inherit;
  }

}

@media (min-width: 320px) and (max-width: 767px) {

  // .caseStudyDisplay{
  //   display: none!important;
  // }

  .recruitMentblockDesc{
    padding: 0px 8px!important;
  }

  .cultureImg{
    padding-bottom: 10px!important;
  }

  .capabilitiMobileShow{
    display: block!important;
  }

  .capabilitiMobileHide{
    display: none!important;
  }

  .sideSocialLinks{
    display: none!important;
  }

  .capabilitiOverview p{
margin: 0px!important;
padding: 16px 0px!important;
  }

  .capabilitiExperTise, .itStaffing .recruitMentblock{
    margin: unset!important;
  }


  .qualIty h6{
padding: 16px 0px!important;

  }

  .capabilitiExperTise img, .itStaffing .recruitMentblock{
    padding-top: 20px!important;
  }

  .custom-container{
    // padding: unset!important;
    padding: 0px 15px!important;
  }
.indHeaderviewport{
  width: 100%;
}

.indHeaderSecdviewport{
  display: none;
}

.flexiBilityList{
  padding-top: 46px;
}
  .bottomMargin{
margin-bottom: 15px;
  }

  .navbar-toggler {
    display: none;
  }

  .mobilemenu {
    display: inherit;
  }

  .f_name h2 {
    font-size: 38px!important;
  }

  .f_name p {
    font-size: 24px!important;
}

  .third_section h6{
    font-size:45px!important;
  }

  .first_section h2,
  .second_section h2,
  .fourth_section h2,
  .fifth_section h2,
  .sixth_section h2,
  .seventh_section h2 {
    font-size: 35px!important;
  }

  .fifth_section .counter, .viSion .counter{
    line-height:60px;
  }

  .fifth_section .counter-box, .viSion .counter-box{
padding-bottom:15px;
  }

  .growthEnabled .valueGrids{
padding-bottom:15px;

  }

  video{
    opacity:0.6
  }

  .custom-container {
    padding:0px 30px;

  }

  .homeScrollmenu{
    display: none;
  }

  .clientsImg{
    width: 50%!important;
    padding: 10px!important;
  }

  .clientsEmpty{
    display: none!important;
  }

  .customBtn{
    font-size: 16px!important;
  }

  /*capabilities*/
  .fullWidthgrid{
 width: 100%!important;
  }

  /*Expertise*/
  #cont{
    columns: 1!important;
  }

  /*industries inner pages*/
  .valueGrids{
border-right: unset!important;
  }

  .industiesTablist{
    padding: 0px 20px;
      }

      .footer{
        padding: 0px 15px;
      }
}



@media (min-width: 320px) and (max-width: 850px) {
  .f_name p, .f_name h2{
    padding-left: 18px!important;
    padding-right: 18px!important;

  }

  .years img{
    padding-left: 20px!important;
  }

  .socialLinks{
    right:2% !important;
  }
}
